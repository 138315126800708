import React, { useState, useEffect, useCallback, useMemo } from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'
import {
  Form,
  Input,
  Select,
  message,
  Checkbox,
  DatePicker,
  Spin,
  Slider,
  InputNumber,
} from 'antd'
import { useDispatch } from 'react-redux'
import { jobCategories, valuesSetHandler, jobFunctions } from '../../../../data'
import { AttatchIcon, RemoveResumeIcon, PrivacyIcon } from '../../../icons'
import 'react-quill/dist/quill.snow.css'
import UpdatePasswordModal from '../../../Modals/updatePassword'
import { strapiURL } from '../../../../config'
import moment from 'moment'
import { getAddressObject, getDescription } from '../../../../functions'
import GooglePlacesAutocomplete from '../../../google-place-autocomplete'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import CandidateUpdateImageModal from '../../../Modals/updateCandidateImageModal'
import { setProfileData } from '../../../../redux/actions/user'
import useGMT from '../../../../hooks/useGTM'

const CancelToken = axios.CancelToken
let source = CancelToken.source()

const CONFIDENTIAL_VERBIAGE =
  'This information is private, anonymized, and will not be shared with employers or recruiters. MedReps uses this data to benchmark industry pay and optimize site experience.'

const SEARCHABLE_VERBIAGE = 'Only applies with an active subscription'

const EDUCATION_LEVEL_OPTIONS = [
  {
    value: 'High School',
    label: 'High School',
  },
  {
    value: 'Associate',
    label: 'Associate',
  },
  {
    value: 'Bachelors',
    label: 'Bachelors',
  },
  {
    value: 'Masters',
    label: 'Masters',
  },
  {
    value: 'Doctorate',
    label: 'Doctorate',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]

const COMPENSATION_MIN = 0
const COMPENSATION_MAX = 500000
const COMPENSATION_STEP = 1

const marks = {
  [COMPENSATION_MIN]: `$${COMPENSATION_MIN.toLocaleString()}`,
  [COMPENSATION_MAX]: `$${COMPENSATION_MAX.toLocaleString()}`,
}

const CandidateProfilePage = ({
  userProfile,
  loadUserProfile,
  user,
  setUserProfile,
}) => {
  const [resume, setResume] = useState(null)
  const [newResume, setNewResume] = useState(null)
  const [loading, setLoading] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [resumeLoading, setResumeLoading] = useState(false)
  const [travelPercentage, setTravelPercentage] = useState(50)
  const [showChange, setShowChange] = useState(false)
  const [searchable, setSearchable] = useState(true)
  const [totalComp, setTotalComp] = useState(0)
  const [form] = Form.useForm()
  const { Option } = Select
  const dispatch = useDispatch()
  const [location, setLocation] = useState(null)

  const { pushUploadResumeEvent } = useGMT()

  const compFormatter = useCallback(value => `$${value.toLocaleString()}`, [])
  let ReactQuill
  if (typeof window !== 'undefined') {
    ReactQuill = require('react-quill')
  }
  useEffect(() => {
    if (userProfile) {
      form.setFieldsValue({
        first_name: userProfile?.first_name || '',
        last_name: userProfile?.last_name || '',
        date_of_birth: userProfile.date_of_birth
          ? moment(userProfile.date_of_birth)
          : null,
        gender: userProfile?.gender || '',
        job_functions: userProfile?.job_functions
          ? userProfile.job_functions.split(',')
          : [],
        industry: userProfile?.industry ? userProfile.industry.split(',') : [],
        medical_sales_experience: userProfile?.medical_sales_experience,
        general_sales_experience: userProfile?.general_sales_experience,
        cover_letter_text: userProfile?.defaultCoverLetter?.text || '',
        is_1099: userProfile.is_1099 || false,
        relocate: userProfile.relocate || false,
        education_level: userProfile?.education_level,
        isSearchable: userProfile?.isSearchable || false,
      })
      setTravelPercentage(
        userProfile.travel_percentage
          ? parseInt(userProfile.travel_percentage)
          : 50
      )
      setSearchable(userProfile?.isSearchable || false)
      setTotalComp(userProfile.compensation_max)
      setResume(userProfile?.defaultResume)
      setLocation(userProfile.location?.placePredict)
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      location,
    })
  }, [location, form])

  const submitHandler = async values => {
    if (!resume && !newResume) {
      message.error('Please Add Resume To Update Your Profile')
      return
    }
    if (newResume && newResume.size > 5000000) {
      message.error(
        'The file you’re trying to upload is too large, please select a file that is 5MB or less.'
      )
      return
    }
    if (!values.location) {
      message.error('Please enter Location')
      return
    }

    setLoading(true)
    var bodyFormData = new FormData()
    let data = {
      ...values,
      name: values.first_name + ' ' + values.last_name,
      cover_letter_text: getDescription(values.cover_letter_text),
    }
    /* Location Process */
    if (
      values.location?.value?.place_id &&
      userProfile.location?.placePredict?.value?.place_id !==
        values.location?.value?.place_id
    ) {
      const geocodeData = await geocodeByPlaceId(values.location.value.place_id)
      if (geocodeData[0]) {
        const addressObject = getAddressObject(
          geocodeData[0].address_components
        )
        const locationObject = {
          location: {
            ...addressObject,
            formattedAddress: geocodeData[0].formatted_address,
            latitude: geocodeData[0].geometry.location.lat(),
            longitude: geocodeData[0].geometry.location.lng(),
            place_id: values.location.value.place_id,
          },
          placePredict: values.location,
        }
        data = {
          ...data,
          location: locationObject,
        }
      }
    } else {
      data = {
        ...data,
        location: userProfile?.location || {},
      }
    }

    if (data.job_functions) {
      data.job_functions = data.job_functions.toString()
    }
    if (data.industry) {
      data.industry = data.industry.toString()
    }
    data.cover_letter_id = userProfile?.defaultCoverLetter?.id || null
    data.username = userProfile?.username
    data.travel_percentage = travelPercentage
    data.compensation_min = 0
    data.compensation_max = totalComp
    if (newResume) {
      bodyFormData.append('files.file', newResume)
    }
    bodyFormData.append('data', JSON.stringify(data))
    axios({
      method: 'post',
      url: strapiURL + `/candidate-profiles/update/general`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
      cancelToken: source.token,
    })
      .then(function (res) {
        message.success('Updated')
        setLoading(false)
        dispatch(setProfileData(res.data.entity))
        if (res.data.errMsg === 'resume_parse_error') {
          message.error(
            'There was an error parsing your resume. Please contact info@medreps.com to assist with your resume. Please attach a copy of your resume to the email.'
          )
        } else {
          setNewResume(null)
          setResume(null)
          navigate('/member-dashboard/')
        }
      })
      .catch(function (err) {
        if (axios.isCancel(err)) {
          setLoading(false)
          setCancelling(true)

          var _bodyFormData = new FormData()

          let _data = {
            first_name: userProfile?.first_name || '',
            last_name: userProfile?.last_name || '',
            date_of_birth: userProfile.date_of_birth
              ? moment(userProfile.date_of_birth)
              : null,
            gender: userProfile?.gender || '',
            job_functions: userProfile?.job_functions
              ? userProfile.job_functions.split(',')
              : [],
            industry: userProfile?.industry
              ? userProfile.industry.split(',')
              : [],
            medical_sales_experience: userProfile?.medical_sales_experience,
            general_sales_experience: userProfile?.general_sales_experience,
            cover_letter_text: userProfile?.defaultCoverLetter?.text || '',
            is_1099: userProfile.is_1099 || false,
            relocate: userProfile.relocate || false,
            education_level: userProfile?.education_level,
            isSearchable: userProfile?.isSearchable || false,
          }

          if (_data.job_functions) {
            _data.job_functions = _data.job_functions.toString()
          }
          if (_data.industry) {
            _data.industry = _data.industry.toString()
          }
          _data.username = userProfile?.username
          _data.travel_percentage = userProfile.travel_percentage
            ? parseInt(userProfile.travel_percentage)
            : 50
          _data.compensation_min = 0
          _data.compensation_max = userProfile.compensation_max

          if (newResume) {
            bodyFormData.append('files.file', newResume)
          }

          _bodyFormData.append('data', JSON.stringify(_data))

          axios({
            method: 'post',
            url: strapiURL + `/candidate-profiles/update/general`,
            data: _bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(res => {
              setCancelling(false)
              message.info('Update cancelled')
              dispatch(setProfileData(res.data.entity))
              setLocation(userProfile.location?.placePredict)
              setNewResume(null)
              setResume(null)
              if (res.data.errMsg === 'resume_parse_error') {
                message.error(
                  'There was an error parsing your resume. Please contact info@medreps.com to assist with your resume. Please attach a copy of your resume to the email.'
                )
              }
            })
            .catch(err => {
              setCancelling(false)
              setLoading(false)
            })
        } else {
          message.error('Something went wrong.')
          setLoading(false)
        }
      })
      .catch(err => {
        setCancelling(false)
        setLoading(false)
      })
  }

  const fileAddHandler = e => {
    let file = e?.target?.files[0]
    if (file) {
      // if (
      //   file?.type?.indexOf('pdf', 'doc', 'docx', 'txt', 'rtf', 'odt') === -1
      // ) {
      //   message.error('Please provide a valid pdf file.')
      //   return
      // }
      pushUploadResumeEvent()
      setNewResume(file)
    }
    setResume(null)
  }
  const resumeRemoveHandler = () => {
    setNewResume(null)
    setResume(null)
    if (userProfile?.defaultResume) {
      setResumeLoading(true)
      axios
        .delete(
          strapiURL + `/candidate-profiles/${userProfile?.id}/resume/delete`
        )
        .then(res => {
          loadUserProfile()
          message.success('Resume deleted')
          setResumeLoading(false)
        })
        .catch(err => {
          setResumeLoading(false)
          message.error('Unable to delete resume')
        })
    }
  }

  const viewResume = e => {
    e.preventDefault()
    axios
      .post(strapiURL + `/resumes/getDefaultResume`)
      .then(res => {
        if (typeof window !== 'undefined' && res?.data) {
          let a = document.createElement('a')
          a.href =
            'data:application/octet-stream;base64,' + res?.data?.parsed_resume
          a.download = resume.name
          a.click()
          a.remove()
        }
      })
      .catch(err => {
        message.error('Unable to view resume')
      })
  }

  const locationValue = useMemo(() => {
    return location?.value?.place_id
      ? location
      : location?.label
      ? {
          ...location,
          value: {},
        }
      : null
  }, [location])

  return (
    <div className="container mx-auto pb-8">
      <div className="w-full sm:w-9/12 px-6 sm:px-0">
        <Form
          form={form}
          name="candidate-profile-form"
          layout="vertical"
          onFinish={submitHandler}
        >
          <h1 className="font-sans text-lg font-bold pb-6">Personal</h1>

          {userProfile?.image ? (
            <div
              className="relative h-28 w-28 overflow-hidden"
              onClick={() => setImageModalOpen(true)}
              onMouseEnter={() => {
                setShowChange(true)
              }}
              onMouseLeave={() => {
                setShowChange(false)
              }}
            >
              <img
                className="cursor-pointer h-28 w-28"
                src={userProfile?.image?.url || ''}
                alt="user-image"
              />
              <div
                className={` bg-black/[0.3] w-full absolute bottom-0 text-center py-1 cursor-pointer ${
                  showChange ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <span className="text-white text-xs font-bold font-sans">
                  Change
                </span>
              </div>
            </div>
          ) : (
            <div
              onClick={() => setImageModalOpen(true)}
              onMouseEnter={() => {
                setShowChange(true)
              }}
              onMouseLeave={() => {
                setShowChange(false)
              }}
              className="h-28 w-28 bg-gray-300 bg-gray-100 relative cursor-pointer overflow-hidden cursor-pointer flex items-center"
            >
              <div
                className={`w-full bg-black/[0.3] absolute bottom-0 -mt-2 flex justify-center py-1 ${
                  showChange ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <span className="text-white text-xs font-bold font-sans">
                  Add
                </span>
              </div>
            </div>
          )}
          <div className="flex flex-col md:flex-row flex-wrap justify-between w-full pt-12">
            <Form.Item
              name="first_name"
              label="First Name"
              className="theme-form-item w-full sm:w-48% mb-8"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Your First Name"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              className="theme-form-item w-full sm:w-48% mb-8"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Your Last Name"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
          </div>
          <Form.Item
            name="location"
            label="Address"
            className="google-place-autocomplete w-full mb-8"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <GooglePlacesAutocomplete
              value={locationValue}
              onChange={val => setLocation(val)}
            />
          </Form.Item>
          <div className="flex flex-col md:flex-row flex-wrap justify-between w-full">
            <Form.Item
              name="date_of_birth"
              label="Date of Birth"
              className="theme-form-item w-full sm:w-48% mb-8"
              tooltip={{
                icon: <PrivacyIcon tooltipText={CONFIDENTIAL_VERBIAGE} />,
              }}
            >
              <DatePicker
                disabledDate={current => {
                  let customDate = moment().format('YYYY-MM-DD')
                  return current && current > moment(customDate, 'YYYY-MM-DD')
                }}
                format={'MM/DD/YYYY'}
                placeholder="Enter Date Of Birth"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
            <Form.Item
              name="gender"
              label="Gender"
              className="theme-form-item w-full sm:w-48% mb-8"
              tooltip={{
                icon: <PrivacyIcon tooltipText={CONFIDENTIAL_VERBIAGE} />,
              }}
            >
              <Select placeholder="Enter Your Gender">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          </div>
          <h1 className="font-sans text-lg font-bold pb-6">Professional</h1>
          <h1 className="font-sans text-lg font-bold mt-4 theme-form-custom-label-required">
            Industry
          </h1>
          <Form.Item
            className="theme-form-item mb-8 theme-rounded-checkboxes"
            name="industry"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Checkbox.Group>
              {jobCategories.map((category, index) => {
                return (
                  <Checkbox
                    className="w-1/2 sm:w-1/4 mb-4 font-normal"
                    key={index}
                    value={valuesSetHandler(category)}
                  >
                    {category}
                  </Checkbox>
                )
              })}
            </Checkbox.Group>
          </Form.Item>
          <h1 className="font-sans text-lg font-bold mt-4 theme-form-custom-label-required">
            Function
          </h1>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
            className="theme-form-item mb-8 theme-rounded-checkboxes"
            name="job_functions"
          >
            <Checkbox.Group>
              {jobFunctions.map((pFunction, index) => {
                return (
                  <Checkbox
                    className="w-1/2 sm:w-1/4 mb-4 font-normal"
                    key={index}
                    value={valuesSetHandler(pFunction)}
                  >
                    {pFunction}
                  </Checkbox>
                )
              })}
            </Checkbox.Group>
          </Form.Item>
          <div className="flex flex-col md:flex-row justify-between">
            <Form.Item
              name="general_sales_experience"
              label="Years of Total Experience"
              className="theme-form-item w-full sm:w-48% mb-8"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
              ]}
            >
              <InputNumber
                type="number"
                placeholder="Enter Years of Total Experience"
                min={0}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                className="w-full placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
            <Form.Item
              name="medical_sales_experience"
              label="Years of Medical Sales Experience"
              className="theme-form-item w-full sm:w-48% mb-8"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                placeholder="Enter Years of Medical Sales Experience"
                className="w-full placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
          </div>
          <div className="flex-col md:flex-row flex justify-between">
            <Form.Item
              name="education_level"
              label="Education Level"
              className="theme-form-item w-full sm:w-48% mb-8"
            >
              <Select placeholder="Enter Your Education Level">
                {EDUCATION_LEVEL_OPTIONS.map(item => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div className="w-full sm:w-48%">
              <Form.Item
                name="total_compensation"
                label="Current Total Compensation"
                className="theme-form-item w-full mb-8 multiple-slider current-total-compensation-candidate-profile"
                tooltip={{
                  icon: <PrivacyIcon tooltipText={CONFIDENTIAL_VERBIAGE} />,
                }}
              >
                <div className="mt-8 hidden lg:block">
                  <Slider
                    onChange={setTotalComp}
                    value={totalComp}
                    max={COMPENSATION_MAX}
                    step={COMPENSATION_STEP}
                    marks={marks}
                    tipFormatter={compFormatter}
                  />
                </div>
                <div className="block lg:hidden">
                  <InputNumber
                    onChange={value => {
                      setTotalComp(value)
                    }}
                    value={totalComp}
                    type="number"
                    placeholder="Enter Total Compensation"
                    min={0}
                    max={500000}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    className="w-full placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="pb-4">
            <div className="w-full sm:w-48%">
              <label className="theme-form-custom-label">
                Willing to Travel Up to
              </label>
              <Form.Item
                name="travel_percentage"
                label=""
                className="theme-form-item w-full mb-8 multiple-slider"
              >
                <div className="flex items-center justify-between mt-4">
                  <span className="font-semibold text-sm font-sans">
                    {travelPercentage}%
                  </span>
                </div>
                <div className="mt-8 hidden lg:block">
                  <Slider
                    onChange={val => {
                      setTravelPercentage(val)
                    }}
                    value={travelPercentage}
                    max={100}
                    min={0}
                  />
                </div>
                <div className="block lg:hidden">
                  <InputNumber
                    onChange={val => {
                      setTravelPercentage(val)
                    }}
                    value={travelPercentage}
                    type="number"
                    placeholder="Enter Total Compensation"
                    max={100}
                    min={0}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    className="w-full placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="pb-4 flex items-start lg:items-center">
            <div className="w-48%">
              <Form.Item
                name="is_1099"
                label=""
                className="theme-form-item w-full mb-8"
                valuePropName="checked"
              >
                <Checkbox className="search-filter-dropdown-item">
                  Open to 1099 Roles
                </Checkbox>
              </Form.Item>
            </div>
            <div className="w-48%">
              <Form.Item
                name="relocate"
                label=""
                className="theme-form-item w-full mb-8"
                valuePropName="checked"
              >
                <Checkbox className="search-filter-dropdown-item">
                  Willing to Relocate
                </Checkbox>
              </Form.Item>
            </div>
            <div className="w-48% flex">
              <Form.Item
                name="isSearchable"
                className="theme-form-item w-full mb-8"
                valuePropName="checked"
              >
                <Checkbox
                  className="search-filter-dropdown-item"
                  onChange={e => {
                    if (e.target.checked) {
                      setSearchable(true)
                    } else {
                      setSearchable(false)
                    }
                  }}
                >
                  <div className="flex items-center">
                    Searchable by Employers and Recruiters
                  </div>
                </Checkbox>
              </Form.Item>
              <PrivacyIcon tooltipText={SEARCHABLE_VERBIAGE} />
            </div>
          </div>

          <h1 className="font-sans text-lg font-bold mt-4 theme-form-custom-label-required">
            Your Resume
          </h1>
          <Form.Item name="resume" className="theme-form-item mb-8">
            {resume || newResume ? (
              <div className="flex items-center">
                <a className="flex items-center" onClick={viewResume}>
                  <AttatchIcon />
                  <p className="mb-0 mx-3">
                    {resume ? resume.name : newResume ? newResume.name : ''}
                  </p>
                </a>
                <div
                  className="cursor-pointer"
                  onClick={() => resumeRemoveHandler()}
                >
                  <RemoveResumeIcon />
                </div>
                {resumeLoading ? <Spin className="action-loader ml-2" /> : null}
              </div>
            ) : (
              <div />
            )}
            {!resume && !newResume ? (
              <>
                <div
                  className={`bg-fileBg border border-voyage border-dashed rounded cursor-pointer relative py-16 flex items-center justify-center mt-4`}
                >
                  <input
                    onChange={e => fileAddHandler(e)}
                    type="file"
                    accept=".pdf , .doc , .docx , .txt , .rtf , .odt"
                    className="opacity-0 w-full absolute inset-0 cursor-pointer"
                  />

                  <p className="font-sans text-sm font-semibold mb-0">
                    <span className="underline text-voyage">Upload</span> Resume
                    file
                  </p>
                </div>

                <p className="font-sans text-xs mt-2">
                  Valid file extension is{' '}
                  <span className="text-voyage">
                    DOC, DOCX, TXT, RTF, ODT, PDF
                  </span>
                </p>
              </>
            ) : (
              <div />
            )}
          </Form.Item>

          <Form.Item
            name="cover_letter_text"
            label="Cover Letter Text"
            className="theme-form-item w-full mb-8"
          >
            {typeof window !== 'undefined' && userProfile && (
              <ReactQuill
                defaultValue=""
                className="quill-editor placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                theme="snow"
              />
            )}
          </Form.Item>
          <button className="bg-voyage py-3 px-8 font-sans text-white text-sm font-bold rounded mr-2">
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button
            type="button"
            onClick={() => {
              if (loading) {
                source.cancel('Update Cancelled')
                source = axios.CancelToken.source()
              } else {
                navigate('/member-dashboard/')
              }
            }}
            className="bg-[#E5EDF0] py-3 px-8 font-sans text-voyage text-sm font-bold rounded mr-2"
          >
            {cancelling ? 'cancelling...' : 'Cancel'}
          </button>
        </Form>
      </div>
      <CandidateUpdateImageModal
        setUserProfile={setUserProfile}
        userProfile={userProfile}
        imageModalOpen={imageModalOpen}
        setImageModalOpen={setImageModalOpen}
      />
      <UpdatePasswordModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        userProfile={userProfile}
      />
    </div>
  )
}
export default CandidateProfilePage
