import React from 'react'
import CandidateProfilePage from '../components/pageComponents/profile/candidate'
import Wrapper from '../components/wrapper'
import PageNav from '../components/pageComponents/common/pageNav'

const Profile = ({ location }) => {
  const Children = props => {
    return (
      <>
        <PageNav navItem="Edit Your Profile" />
        {props.user && props.userProfile ? (
          <CandidateProfilePage {...props} />
        ) : (
          <div
            className="w-full min-h-900px bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url(/loaders/company-profile-loader.png)`,
            }}
          />
        )}
      </>
    )
  }

  return (
    <Wrapper
      title="Profile | MedReps.com"
      privateRoute={true}
      location={location}
    >
      <Children />
    </Wrapper>
  )
}
export default Profile
